.App {
  margin: 0;
  padding: 0;
}

.App-mainbody {
  margin: 50px;
  padding: 0;
}

.Navbar {
  position: sticky;
  top: 0;
  z-index: 100;
}

.Navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0 50px;
  overflow: hidden;
  background-color: #333;
}

.Navbar li {
  float: left;
}

.Navbar li > * {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.Navbar li > *:hover {color: white;}

.Navbar li > *:hover:not(.Navbar-active) {background-color: #111;}

.Navbar li > *.Navbar-bright {background-color: #00a84c;}

.Navbar li > *.Navbar-bright:hover {background-color: #00c257;}

.Navbar li > *.Navbar-active {background-color: #0059ff;}
.Navbar li > *.Navbar-active:hover {background-color: #0059ff;}

.Navbar li.Navbar-right {float: right;}

@media screen and (max-width: 600px) {
  .Navbar li.Navbar-right, 
  .Navbar li {float: none;}

  .Navbar {
    position: relative;
  }
}

.App-toTop {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.App-toTop > * {
  color: #0059ff;
}

.App-toTop > *:hover {
  color: #00ff73;
  cursor: pointer;
}
